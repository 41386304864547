/** @jsx jsx */
import * as React from 'react';
import { css, jsx } from '@emotion/core';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';

import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from 'src/actions/accountActions';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core';
import UpdateProfileService from 'src/services/updateProfileServices';
import {designations, showSpecialtyFor,specialityOptions, showProgramFor,studentProgramOptions,designationsObject} from "../../../appConstants"
import {Autocomplete} from "@material-ui/lab";
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import "src/views/learning/SingleCourse/_components/Drawer/custom-loader.css"
import * as Yup from 'yup';
import { Formik } from 'formik';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
});
const useStyles = makeStyles(() => ({}));
export default function Popup() {
  const { user } = useSelector(state => state.account);
  const [open, setOpen] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const [profession , setProfession] = React.useState(false)
  const [specialityType, setSpecialityType] =React.useState(false);
  const [specialityList, setSpecialityList] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();


  const handleClickOpen = () => {
    if (
     ( user.first_name == null ||
      (user.first_name == '' && user.last_name == '') ||
      user.last_name == null) || (user.designation_name == null || user.designation_name =='')
    ) {
      setOpen(true);
    }
  };
  //popup hide ends here

  //npi value show hide starts here
  const handleClose = () => {
    setOpen(false);
  };

  function hide() {
    if (
      user.country == 'United States' &&
      (user.designation_name == 'Dentist' ||
        user.designation_name == 'Nurse Practitioner' ||
        user.designation_name == 'Physician Assistant' ||
        user.designation_name == 'Doctor' ||
        user.designation_name == 'Anesthesia Assistant')
    ) {
      setShowResults(true);
    }
    if(user.designation_name == null || user.designation_name == ''){
      setProfession(true)
   }
  }

  
  useEffect(() => {
    let pop = setTimeout(() => handleClickOpen(), 20000);
    hide();

    return () => {
      clearTimeout(pop);
    };
  }, []);
  const classes = useStyles();
  //npi value show hide ends here
  return (
    <Dialog open={open} >
      <DialogTitle style={{ backgroundColor: 'rgb(27, 68, 122)', color: '#fff', fontSize:'25px', textAlign:'center'}}>
        Thanks for registering
      </DialogTitle>
      <DialogContent style={{ backgroundColor: 'rgb(27, 68, 122)' }}>
        <DialogContentText
          style={{ backgroundColor: 'rgb(27, 68, 122)', color: '#fff', textAlign:'center'}}
        >
       
        <div style={{ backgroundColor: 'rgb(27, 68, 122)', color: '#fff', fontSize:'16px',display: 'flex', justifyContent:'center'}}>
        <span style={{marginRight:'5px',marginTop:'2px'}}><img src="/static/icons/warning_white.png" /></span>
        <p>It looks like your profile is incomplete though.</p>
        </div>
        <br/>
        Our platform provides a customized experience based on your profession.
        Take a moment to complete the required fields on this form to continue.
        </DialogContentText>
        <br/><br/>  
     <Formik
      enableReinitialize
      initialValues={{
        email: user.email || '',
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        designation_id: user.designation_id || '',
        specialty: user.specialty || '',
        npi_number:user.npi_number || ''
      }}
      validationSchema={Yup.object().shape({
      first_name: Yup.string()
        .max(255)
        .matches(/^[A-Za-zÀ-ÿ-' ]+$/, 'Only English Letters Allowed')
        .required('Required*'),
      last_name: Yup.string()
        .max(255)
        .matches(/^[A-Za-zÀ-ÿ-' ]+$/, 'Only English Letters Allowed')
        .required('Required*'),
      npi_number: Yup.string()
        .max(10)
        .matches(/^\d{10}$/, 'NPI number must be exactly 10 digits')
        .nullable()  // Allows null or undefined values, making the field optional
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const filteredNames = Object.keys(values).filter(name =>           
            /designation_id-[a-zA-Z 0-9]/.test(name)
          );
          if (filteredNames.length > 0) {
            const lastValue = filteredNames[filteredNames.length - 1];
            values.designation_id =
              designationsObject[
                lastValue.replace('designation_id-', '')
              ]['id'];
          } else if (!values.designation_id) {
            values.designation_id = '';
          }
          const filteredSpecialty = Object.keys(values).filter(name =>
            /specialty-option-[a-zA-Z 0-9]/.test(name)
          );
          if (filteredSpecialty.length > 0) {
            const lastValue = filteredSpecialty[filteredSpecialty.length - 1];
            values.specialty = lastValue.replace(
              'specialty-option-',
              ''
            );
          } else if (!values.specialty) {
            values.specialty = '';
          }
          await dispatch(
            updateProfile(
              values
            )
          );
          resetForm();
      
          handleClose();
          setStatus({ success: true });
          enqueueSnackbar('Profile updated', {
            variant: 'success'
          });
          const coursesData = await UpdateProfileService.addMyCredPoints(
            user.wpUserID
          );
          if (coursesData) {
            enqueueSnackbar('Good job! you just earned 10 medtigo points.', {
              variant: 'success'
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({
            submit: error || 'Some error occurred, please try again.'
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
       
      }) => (
        <form onSubmit={handleSubmit}>
<Box
  css={{
    '.MuiTextField-root *:not(.MuiFormHelperText-root)': {
      color: '#FFF',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderBottomColor: '#fff',
      },
    },
    color: '#FFF',
  }}
>
            <TextField
                  required
                   id="custom-css-outlined-input"
                   fullWidth
                   label="First Name"
                   variant="standard"
                   name="first_name"
                   onBlur={handleBlur}
                   onChange={handleChange}
                   type="first_name"
                   value={values.first_name || ''}
                   inputProps={{ autoComplete: 'off' }}
                   error={Boolean(touched.first_name && errors.first_name)}
                   helperText={touched.first_name && errors.first_name}
            />
            <br/> 
            <br/> 
       <TextField
              variant="standard"
              fullWidth
              label="Last Name"
              name="last_name"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="last_name"
              value={values.last_name || ''}
              inputProps={{ autoComplete: 'off' }}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}            />
             <br/> 
            <br/> 
            {
              profession ? 
              <>
              <Autocomplete
                    getOptionLabel={option => option.name}
                    id="designation_id"
                    filterOptions={filterOptions}
                    options={designations}
                    name={'designation_id'}
                    onChange={(event,value) => {
                      // const text = event.target.innerText;
                      const text = value ? value.name : '';
                      handleChange('designation_id-' + text)(event);
                     setFieldValue('designation_id', text);
                     
                      if (text) {
                        let designation_id = designationsObject[text]['id'];
                        if (showSpecialtyFor.indexOf(designation_id) > -1) {
                          setSpecialityList(specialityOptions);
                          setSpecialityType(true);
                        } else if (
                          showProgramFor.indexOf(designation_id) > -1
                        ) {
                          setSpecialityList(studentProgramOptions);
                          setSpecialityType(true);
                        } else {
                          setSpecialityList([]);
                          setSpecialityType(false);
                          values.specialty = '';
                        }
                      } else {
                        setSpecialityList([]);
                        setSpecialityType(false);
                        values.specialty = '';
                      }
                    }}
                  
                    renderInput={params => {
                      return (
                        <TextField
                          label="Profession"
                          variant="standard"
                          {...params}
                          margin="none"
                          required
                          className={classes.textFields}
                          name={'designation'}
                        />
                      );
                    }}
                  />
                  {specialityType && (<br/>)}
              
                { specialityType && (
                  
                    <Autocomplete
                      getOptionLabel={option => option}
                      id="specialty"
                      filterOptions={filterOptions}
                      options={specialityList}
                      name={'specialty'}
                      onChange={event => {
                        const text = event.target.innerText;
                        handleChange('specialty-option-' + text)(event);
                      }}
                      defaultValue={user.specialty || ''}
                      renderInput={params => {
                        return (
                          <TextField
                            label="Specialty"
                            variant="standard"
                            {...params}
                            margin="none"
                            required
                            className={classes.textFields}
                            name="specialty"
                          />
                        );
                      }}
                    />
                  
                )}
                        
                  <br />
                  </>
                : null
                
            }

          {showResults ? (
              <TextField
                fullWidth
                label="NPI Number"
                name='npi_number'
                type="npi_number"
                variant="standard"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.npi_number && errors.npi_number)}
                helperText={touched.npi_number && errors.npi_number}
              />
            ) : null}

           
            <DialogActions style={{ backgroundColor: '#rgb(27, 68, 122)' }}>
              {/* <button
                css={css`
                  color: white;
                  padding: 3px 20px;
                  cursor: pointer;
                  border: 1px solid white;
                  background: none;
                  border-radius: 10px;
                  &:hover {
                    background-color: white;
                    color: #1b447a;
                    ease-in-out: 1;
                  }
                `}
                onClick={handleClose}
              >
                Skip for now
              </button> */}
              <button
                css={css`
                  color: white;
                  padding: 3px 20px;
                  cursor: pointer;
                  border: 1px solid #3aa4e0;
                  background: none;
                  background-color: #3aa4e0;
                  border-radius: 10px;
                `}
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </DialogActions>
          </Box>
        </form>
      )}
    </Formik>
    </DialogContent>
    </Dialog>
  );
}
